/* eslint-disable react/no-array-index-key */
import { ChainId, Currency, Token } from '@pancakeswap/sdk'
import { Text, QuestionHelper, AutoColumn } from '@pancakeswap/uikit'
import styled from 'styled-components'
import useNativeCurrency from 'hooks/useNativeCurrency'
import { useTranslation } from '@pancakeswap/localization'

import { SUGGESTED_BASES } from 'config/constants/exchange'
import { AutoRow } from '../Layout/Row'
import { CurrencyLogo } from '../Logo'
import { CommonBasesType } from './types'

const ButtonWrapper = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
`

const BaseWrapper = styled.div<{ disable?: boolean }>`
  border: 1px solid ${({ theme, disable }) => (disable ? 'transparent' : theme.colors.dropdown)};
  border-radius: 10px;
  display: flex;
  padding: 6px;
  align-items: center;
  :hover {
    cursor: ${({ disable }) => !disable && 'pointer'};
    background-color: ${({ theme, disable }) => !disable && theme.colors.background};
  }
  background-color: ${({ theme, disable }) => disable && theme.colors.dropdown};
  opacity: ${({ disable }) => disable && '0.4'};
`

const RowWrapper = styled.div`
  white-space: nowrap;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
`

export default function CommonBases({
  chainId,
  onSelect,
  selectedCurrency,
  commonBasesType,
}: {
  chainId?: ChainId
  commonBasesType
  selectedCurrency?: Currency | null
  onSelect: (currency: Currency) => void
}) {
  const native = useNativeCurrency()
  // const { t } = useTranslation()
  // const pinTokenDescText = commonBasesType === CommonBasesType.SWAP_LIMITORDER ? t('Common tokens') : t('Common bases')

  return (
    <AutoColumn gap="md">
      <AutoRow>
        <p className="text-sm text-gray-6">Common Tokens</p>
      </AutoRow>
      <div className="flex flex-row flex-wrap items-center gap-2 max-w-[400px]">
        <button
          type="button"
          onClick={() => {
            if (!selectedCurrency || !selectedCurrency.isNative) {
              onSelect(native)
            }
          }}
          disabled={selectedCurrency?.isNative}
          className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative p-2 rounded-[14px] bg-[#151027]/10"
        >
          <CurrencyLogo currency={native} style={{ marginRight: 8 }} />
          <p className="text-gray-6">{native?.symbol}</p>
        </button>
        {(chainId ? SUGGESTED_BASES[chainId] || [] : []).map((token: Token, index: number) => {
          const selected = selectedCurrency?.equals(token)
          return (
            <button
              key={index}
              type="button"
              onClick={() => !selected && onSelect(token)}
              disabled={selected}
              className="flex justify-start items-center flex-grow-0 flex-shrink-0 relative p-2 rounded-[14px] bg-[#151027]/10"
            >
              <CurrencyLogo currency={token} style={{ marginRight: 8, borderRadius: '50%' }} />
              <p className="flex-grow-0 flex-shrink-0 text-left text-[#151027]">{token.symbol}</p>
            </button>
          )
        })}
      </div>
    </AutoColumn>
  )
}
