import { useState } from 'react'
import { Token } from '@pancakeswap/sdk'
import { ButtonMenu, ModalBody } from '@pancakeswap/uikit'
import styled from 'styled-components'
import { TokenList } from '@pancakeswap/token-lists'
import { useTranslation } from '@pancakeswap/localization'
import { cn } from 'utils'
import ManageLists from './ManageLists'
import ManageTokens from './ManageTokens'
import { CurrencyModalView } from './types'

const StyledButtonMenu = styled(ButtonMenu)`
  width: 100%;
`

export default function Manage({
  setModalView,
  setImportList,
  setImportToken,
  setListUrl,
}: {
  setModalView: (view: CurrencyModalView) => void
  setImportToken: (token: Token) => void
  setImportList: (list: TokenList) => void
  setListUrl: (url: string) => void
}) {
  const [showLists, setShowLists] = useState(true)

  return (
    <ModalBody style={{ overflow: 'visible' }}>
      <div className="flex justify-center items-center flex-grow-0 flex-shrink-0  w-full p-0.5 rounded-lg border border-lime-9 bg-white mb-8">
        <button
          type="button"
          onClick={() => setShowLists((prev) => !prev)}
          className={cn(
            'flex-1 flex justify-center items-center flex-grow relative overflow-hidden gap-2.5 px-5 py-3 rounded-lg',
            showLists && 'bg-lime-9',
          )}
        >
          <p className={cn('flex-grow-0 flex-shrink-0 text-base font-medium text-left', showLists && 'text-white')}>
            Lists
          </p>
        </button>
        <button
          type="button"
          onClick={() => setShowLists((prev) => !prev)}
          className={cn(
            'flex-1 flex justify-center items-center flex-grow relative overflow-hidden gap-2.5 px-5 py-3 rounded-lg',
            !showLists && 'bg-lime-9',
          )}
        >
          <p className={cn('flex-grow-0 flex-shrink-0 text-base font-medium text-left', !showLists && 'text-white')}>
            Tokens
          </p>
        </button>
      </div>
      {showLists ? (
        <ManageLists setModalView={setModalView} setImportList={setImportList} setListUrl={setListUrl} />
      ) : (
        <ManageTokens setModalView={setModalView} setImportToken={setImportToken} />
      )}
    </ModalBody>
  )
}
